import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-labels";
import { Box, Divider, Typography } from "@mui/material";
import { useRef } from "react";

ChartJS.helpers.extend(ChartJS.elements, {
  arc: ArcElement,
});

ChartJS.helpers.extend(ChartJS.plugins, {
  tooltip: Tooltip,
  legend: Legend,
});

export const GraficoPizza = ({ dataPage }) => {
  const chartRef = useRef(null);
  const data = {
    labels: ["1ª Compra", "Recompras"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          dataPage.count_customers_first_purchase,
          dataPage.count_customers_shopping -
          dataPage.count_customers_first_purchase,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Função para calcular a porcentagem
  const calculatePercentage = (value) => {
    return ((value / dataPage.count_customers_shopping) * 100).toFixed(2) + "%";
  };

  return (
    <>
      <Box
        display={["grid", "grid", "flex"]}
        gap={2}
        justifyContent={"center"}
        width={"100%"}
      >
        <Box width={"330px"}>
          <Typography>Clientes</Typography>
          <Pie
            data={data}
            ref={chartRef}
            options={{
              plugins: {
                datalabels: {
                  formatter: (value, ctx) => {
                    const dataset = ctx.chart.data.datasets[ctx.datasetIndex];

                    const total = dataset.data.reduce(
                      (acc, currentValue) => acc + currentValue,
                      0
                    );
                    const percentage = ((value / total) * 100).toFixed(2) + "%";
                    return `${value} | ${percentage}`;
                  },
                  color: "gray", // Cor dos rótulos
                  font: {
                    weight: "bold",
                  },
                },
              },

              tooltips: {
                callbacks: {
                  label: (tooltipItem, data) => {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const currentValue = dataset.data[tooltipItem.index];
                    return (
                      data.labels[tooltipItem.index] +
                      ": " +
                      currentValue +
                      " (" +
                      calculatePercentage(currentValue) +
                      ")"
                    );
                  },
                },
              },
              legend: {
                display: true,
                position: "left",
              },
            }}
          />
          <Typography>
            Total:{" "}
            {dataPage.count_customers_first_purchase +
              (dataPage.count_customers_shopping -
                dataPage.count_customers_first_purchase)}
          </Typography>
        </Box>
        <Divider
          orientation="vertical"
          sx={{
            height: "100%",
          }}
        />
        <Box width={"330px"}>
          <Typography>Pedidos</Typography>
          <Pie
            data={{
              labels: ["Sem cadastro", "Com cadastro"],
              datasets: [
                {
                  label: "# of Votes",
                  data: [
                    dataPage.count_sales_without_customer_registered,
                    dataPage.count_sales -
                    dataPage.count_sales_without_customer_registered,
                  ],
                  backgroundColor: [
                    "rgba(255, 206, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 159, 64, 0.2)",
                  ],
                  borderColor: [
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              plugins: {
                datalabels: {
                  formatter: (value, ctx) => {
                    const dataset = ctx.chart.data.datasets[ctx.datasetIndex];
                    const total = dataset.data.reduce(
                      (acc, currentValue) => acc + currentValue,
                      0
                    );
                    const percentage = ((value / total) * 100).toFixed(2) + "%";
                    return `${value} | ${percentage}`;
                  },
                  color: "gray", // Cor dos rótulos
                  font: {
                    weight: "bold",
                  },
                },
              },
              tooltips: {
                callbacks: {
                  label: (tooltipItem, data) => {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const currentValue = dataset.data[tooltipItem.index];
                    return (
                      data.labels[tooltipItem.index] +
                      ": " +
                      currentValue +
                      " (" +
                      calculatePercentage(currentValue) +
                      ")"
                    );
                  },
                },
              },
              legend: {
                display: true,
                position: "left",
              },
            }}
          />
          <Typography>
            Total:{" "}
            {dataPage.count_sales_without_customer_registered +
              (dataPage.count_sales -
                dataPage.count_sales_without_customer_registered)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
