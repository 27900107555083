import React from "react";

import { Container, Row } from "react-bootstrap";
import "chartjs-plugin-datalabels";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import Title from "../../../components/title";

import { PermissionsPage } from "../../../util/permissionsPage";
import { Box, Stack, Typography } from "@mui/material";
import { LoadingTable } from "../../../components/Loading/LoadingTable";
import Select from "react-select";
import { useEstoqueMesLoja } from "./useEstoqueMesLoja";
import { TituloTabela } from "../../../components/TituloTabela/TituloTabela";
import DataTable, { defaultThemes } from "react-data-table-component";
import { NoData } from "../../../components/NoData/NoData";

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? " whitesmoke" : "white",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: " #eb0058",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#FFF",
    fontWeight: "bold",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#ff0d6c",
      color: "white",
    },
  }),
};

const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};

function EstoqueMesLoja() {
  const { columns, isLoading, basesDisponiveis, lojasDisponiveis, defaultValueBase, tableData, lojasSelecionadas, filtrarBases, handleFiltroLoja } = useEstoqueMesLoja()

  return (
    <>
      <div className="container-app">
        {PermissionsPage("estoqueMesLoja") && (
          <Container>
            <div className="container-conteudo">
              <Container className="containerFlexBox">
                <Title title="ESTOQUE MÊS POR LOJA" />
                <Stack>

                  <Box pb={1}>
                    <Typography
                      textAlign={"initial"}
                      fontWeight={"bold"}
                      component={"span"}>
                      Base
                    </Typography>
                    <Select
                      options={basesDisponiveis.map((item) => ({
                        label: item.base,
                        value: item.codBase,
                      }))}
                      onChange={(value) => filtrarBases(value.value)}
                      placeholder="Selecione uma base"
                      styles={colourStyles}
                      isDisabled={isLoading}
                      value={{ label: defaultValueBase?.base, value: defaultValueBase?.codBase }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      textAlign={"initial"}
                      fontWeight={"bold"}
                      component={"span"}>
                      Lojas
                    </Typography>
                    <Select
                      options={lojasDisponiveis.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      isMulti
                      onChange={(values) => handleFiltroLoja(values.map(item => item.value))}
                      placeholder="Selecione uma loja"
                      styles={colourStyles}
                      isDisabled={isLoading}
                      value={lojasSelecionadas}
                    />
                    <Typography variant="caption" component={"span"}>
                      *Atenção: Quando nenhuma loja estiver selecionada, todas as
                      lojas serão retornadas.*
                    </Typography>
                  </Box>
                </Stack>
              </Container>
              {isLoading ? (
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"90vh"}
                >
                  <LoadingTable isLoading={isLoading} />
                </Box>
              ) : (
                <Container className="mt-2">
                  <Row className="wrapper">
                    <TituloTabela>Estoque por grupo - Preço custo</TituloTabela>
                    <DataTable
                      // dense
                      striped
                      noDataComponent={<NoData />}
                      columns={columns}
                      customStyles={customStyles}
                      data={tableData}
                    />
                  </Row>
                </Container>
              )}
            </div>
          </Container>
        )}
      </div>
    </>
  );
}

export default EstoqueMesLoja;
