import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import Title from "../../components/title";
import "react-data-table-component-extensions/dist/index.css";
import {
  arrayBases,
  converterDataFormatoPadrao,
  converterLoja,
  converterMes,
  converterNomeLoja,
  fAnosDisponiveis,
} from "../../util/loja";
import Carregando from "../../components/Carregando";

import ButtonVoltar from "../../components/ButtonVoltar";
import { vendasGrupoTabelaPorFornecedor } from "../../util/tables";
import { FiltroPeriodo } from "../../components/FiltroPeriodo/FiltroPeriodo.jsx";
import TabelaNext from "../../components/componentteste/TabelaNext";
import { TituloTabela } from "../../components/TituloTabela/TituloTabela";
import toast from "react-hot-toast";

import { PermissionsPage } from "../../util/permissionsPage";
import { vendasService } from "../../services/vendas";
import { useUserData } from "../../hooks/useUserData";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { LoadingTable } from "../../components/Loading/LoadingTable";
import { MultiSelectFilterBases } from "../../components/FilterLojaMetas/MultiSelectFilterBases";
import Select from "react-select";
import {
  ButtonFilter,
  ButtonToday,
} from "../../components/DatePikerFilter/styles";

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? " whitesmoke" : "white",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: " #eb0058",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#FFF",
    fontWeight: "bold",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#ff0d6c",
      color: "white",
    },
  }),
};

function VendasPorFornecedor() {
  const [lojaTable, setLojaTable] = useState(false);
  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso;
  const acessBase = userData?.baseAcesso;

  const [isLoading, setIsLoading] = useState(true);
  const [periodoInputInicio, setPeriodoInputInicio] = useState("");
  const [periodoInputFim, setPeriodoInputFim] = useState("");
  const [periodoInputInicioAux, setPeriodoInputInicioAux] = useState("");
  const [periodoInputFimAux, setPeriodoInputFimAux] = useState("");
  const [periodoAno, setPeriodoAno] = useState(false);
  const [showBaseFilterButton, setShowBaseFilterButton] = useState(false);
  const [anosDisponiveis, setAnosDisponiveis] = useState([]);
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [dadosGraficoAgrupado, setDadosGraficoAgrupado] = useState({
    labels: [],
    datasets: [],
  });
  const [dadosGraficoAgrupado1, setDadosGraficoAgrupado1] = useState({
    labels: [],
    datasets: [],
  });
  const [dadosGraficoAgrupado2, setDadosGraficoAgrupado2] = useState({
    labels: [],
    datasets: [],
  });
  const [dadosGerais, setDadosGerais] = useState(0);
  const [dadosGeraisPA, setDadosGeraisPA] = useState(0);
  const [dadosGeraisGrupo, setDadosGeraisGrupo] = useState([]);
  const [dadosGeraisGrupoPA, setDadosGeraisGrupoPA] = useState([]);
  const [filtrosAtivosLojasTexto, setFiltrosAtivosLojasTexto] = useState("");
  const [filtrosLojasSelecionadas, setFiltrosLojasSelecionadas] = useState([]);
  const [codLojaAtiva, setCodLojaAtiva] = useState(false);
  const [dadosParaTabela, setDadosParaTabela] = useState([]);
  const [auxDadosParaTabela, setAuxDadosParaTabela] = useState([]);
  const [dadosParaTabelaLoja, setDadosParaTabelaLoja] = useState([]);
  const [exibeTabela, setExibeTabela] = useState(false);
  const [tipoPeriodo, setTipoPeriodo] = useState("");
  const [auxTipoPeriodo, setAuxTipoPeriodo] = useState("");
  const [filtradoPorLoja, setFiltradoPorLoja] = useState(false);
  const [grupoAtivo, setGrupoAtivo] = useState("");
  const [nivelAtivoDoGrafico, setNivelAtivoDoGrafico] = useState(0);
  const [auxAnoAtual, setAuxAnoAtual] = useState(0);
  const [auxAnoAnterior, setAuxAnoAnterior] = useState(0);
  const [nivelAcesso, setNivelAcesso] = useState([]);

  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [basesSelecionadas, setBasesSelecionadas] = useState([]);
  const [basesSelecionadasTemp, setBasesSelecionadasTemp] = useState([]);
  const [dadosDisponiveis, setDadosDisponiveis] = useState(true);
  const [carregando, setCarregando] = useState(false);

  const buscarNivelAcesso = () => {
    if (accessLevel) {
      setNivelAcesso(accessLevel);
      setLojasDisponiveis(accessLevel);
      const bases = arrayBases(acessBase);
      setBasesDisponiveis(bases);
      setBasesSelecionadas([bases[0].codBase]);
      setBasesSelecionadasTemp([bases[0].codBase]);
    } else {
      setCarregando(false);
      setIsLoading(false);
    }
  };

  function preencherInputPeriodo() {
    let dataHoje = new Date();
    let [inicioAno, hoje] = converterDataFormatoPadrao(dataHoje);
    setPeriodoInputInicioAux(inicioAno);
    setPeriodoInputFimAux(hoje);
    setPeriodoInputInicio(inicioAno);
    setPeriodoInputFim(hoje);
  }

  async function preencherDados(tipo) {
    setTipoPeriodo(tipo);
    if (tipo !== "mes") setAuxTipoPeriodo(tipo);
    setFiltradoPorLoja(false);
    setFiltrosLojasSelecionadas([]);
    setDadosGeraisGrupo("");
    setDadosGeraisGrupoPA("");
    setGrupoAtivo("");
    setFiltrosAtivosLojasTexto("");
    setNivelAtivoDoGrafico(0);
    if (tipoPeriodo === "mes") {
      tipo = auxTipoPeriodo;
    }
    if (tipo === "input") {
      setExibeTabela(true);
      // setExibeTabelaGlobal(true);
      var anoAtual = periodoInputInicio.split("-")[0];
      var anoAnterior = parseInt(anoAtual) - 1;
      setAuxAnoAtual(anoAtual);
      setAuxAnoAnterior(anoAnterior);
    } else if (tipo === "ano") {
      var anoAtual = periodoAno.split(",");
      anoAtual = anoAtual.sort((a, b) => a - b);
      var anoAnterior;
      if (anoAtual.length > 1) {
        anoAnterior = anoAtual[anoAtual.length - 2];
      } else {
        anoAnterior = 0;
      }
      anoAtual = anoAtual[anoAtual.length - 1];
      setAuxAnoAtual(anoAtual);
      setAuxAnoAnterior(anoAnterior);
    }
    setCarregando(true);
    setIsLoading(true);
    const toastId = toast.loading("Esperando dados do RAD");
    window.scrollTo(0, 0);

    try {
      const response = await vendasService.listaVendasFornecedor({
        inicio: periodoInputInicio,
        fim: periodoInputFim,
        ano: periodoAno,
        tipo,
        base: basesSelecionadas,
      });

      if (!response?.data) {
        toast.error("Não há conexão com o RAD", {
          id: toastId,
          duration: 7000,
          style: {
            borderRadius: "0.25rem",
            background: "#333",
            color: "#fff",
          },
        });
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
        return;
      }
      if (response?.status === 200) {
        toast.success("Dados recebidos", {
          id: toastId,
        });
        try {
          setAnosDisponiveis(fAnosDisponiveis(response.data.anos));
        } catch (error) {
          setCarregando(false);
          setIsLoading(false);
          return;
        }
        var dataVendas = response.data.data.vendas;
        var dataUltimoPeriodo = response.data.data.vendasPeriodoAnterior;
        setDadosGerais(dataVendas);
        setDadosGeraisPA(dataUltimoPeriodo);
        let ultimoPeriodoAgrupado = agruparPor(dataUltimoPeriodo, "CodGrp3");
        let ultimoPeriodo = Object.entries(ultimoPeriodoAgrupado);

        let grupoDeAnos = agruparPor(dataVendas, "ano");
        let grupoDeAnosArray = Object.entries(grupoDeAnos);
        let separadoPorGrupo = [];
        grupoDeAnosArray.map((item) => {
          let grupoDeLoja = agruparPor(item[1], "CodGrp3");
          separadoPorGrupo.push(grupoDeLoja);
        });
        let resumoSeparadoPorGrupo = [];
        separadoPorGrupo.map((item) => {
          let item2 = Object.entries(item);
          item2.map((item3) => {
            let total = 0;
            let totalPedidos = 0;
            let ano;
            let codGrupo;
            let nomeFornecedor;
            let totalCusto = 0;
            let totalLucro = 0;
            let porcentagemLucro = 0;
            item3[1].map((item4, index) => {
              if (index == 0) {
                ano = item4.ano;
                nomeFornecedor = item4.NomeGrupo3;
                codGrupo = item4.CodGrp3;
              }
              totalPedidos += !isNaN(parseInt(item4.totalPedidos))
                ? parseInt(item4.totalPedidos)
                : 0;
              total += !isNaN(parseFloat(item4.VlrTotal))
                ? parseFloat(item4.VlrTotal)
                : 0;
              totalCusto += !isNaN(parseFloat(item4.VlrCusto))
                ? parseFloat(item4.VlrCusto)
                : 0;
              totalLucro += !isNaN(parseFloat(item4.VlrLucroBruto))
                ? parseFloat(item4.VlrLucroBruto)
                : 0;
              porcentagemLucro += !isNaN(
                parseFloat(item4.VlrLucroBrutoPorcentagem)
              )
                ? parseFloat(item4.VlrLucroBrutoPorcentagem)
                : 0;
            });
            porcentagemLucro = porcentagemLucro / item3[1].length;
            let resumoLojaAno = {
              ano: ano,
              codGrupo,
              nomeFornecedor,
              total: total,
              totalPedidos: totalPedidos,
              totalCusto,
              totalLucro,
              porcentagemLucro,
            };
            resumoSeparadoPorGrupo.push(resumoLojaAno);
          });
        });

        var agrupado = agruparPor(resumoSeparadoPorGrupo, "ano");
        grupoDeAnosArray = Object.entries(agrupado);

        var periodoAnterior = [];

        ultimoPeriodo.map((item) => {
          item[1].map((item1) => {
            return periodoAnterior.push({
              CodGrp3: item1.CodGrp3,
              ano: item1.ano,
              nomeGrupo: item1.NomeGrupo3,
              porcentagemLucro: item1.VlrLucroBrutoPorcentagem,
              total: item1.VlrTotal,
              totalCusto: item1.VlrCusto,
              totalLucro: item1.VlrLucroBruto,
              totalPedidos: item1.totalPedidos,
            });
          });
        });

        preencherDadosGraficoAgrupado(
          grupoDeAnosArray,
          tipo,
          anoAtual,
          anoAnterior,
          periodoAnterior
        );
        setDadosDisponiveis(true);
        setCarregando(false);
        setIsLoading(false);
      } else {
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function iniciarFiltroHoje() {
    var dataHoje = new Date();
    var [inicioAno, hoje] = converterDataFormatoPadrao(dataHoje);

    setPeriodoInputInicio(hoje);
    setPeriodoInputFim(hoje);

    setPeriodoInputInicioAux(hoje);
    setPeriodoInputFimAux(hoje);
  }
  async function preencherDadosFornecedorEspecifico(tipo) {
    setTipoPeriodo(tipo);
    if (tipo !== "mes") setAuxTipoPeriodo(tipo);
    setFiltradoPorLoja(false);
    setFiltrosLojasSelecionadas([]);

    if (tipoPeriodo === "mes") {
      tipo = auxTipoPeriodo;
    }
    if (tipo === "input") {
      setExibeTabela(true);
      let auxAnoAtual = periodoInputInicio.split("-")[0];
      let auxAnoAnterior = parseInt(auxAnoAtual) - 1;
      setAuxAnoAtual(auxAnoAtual);
      setAuxAnoAnterior(auxAnoAnterior);
    } else if (tipo == "ano") {
      let auxAnoAtual = periodoInputInicio.split(",");
      auxAnoAtual = auxAnoAtual.sort((a, b) => a - b);
      let auxAnoAnterior;
      if (auxAnoAtual.length > 1) {
        auxAnoAnterior = auxAnoAtual[auxAnoAtual.length - 2];
      } else {
        auxAnoAnterior = 0;
      }
      auxAnoAtual = auxAnoAtual[auxAnoAtual.length - 1];
      setAuxAnoAtual(auxAnoAtual);
      setAuxAnoAnterior(auxAnoAnterior);
    }
    setCarregando(true);
    setIsLoading(true);

    window.scrollTo(0, 0);

    try {
      const response = await vendasService.listaVendasFornecedor({
        inicio: periodoInputInicio,
        fim: periodoInputFim,
        ano: periodoAno,
        tipo,
        grupoAtivo,
        base: basesSelecionadas,
      });

      if (response?.status === 200) {
        var dataVendas = response.data.data.vendas;
        var dataPeriodoAnterior = response.data.data.vendasPeriodoAnterior;
        setDadosGeraisGrupo(dataVendas);
        setDadosGeraisGrupoPA(dataPeriodoAnterior);
        let grupoDeAnos = agruparPor(dataVendas, "ano");
        let grupoDeAnosArray = Object.entries(grupoDeAnos);
        let separadoPorLoja = [];
        grupoDeAnosArray.map((item) => {
          let grupoDeLoja = agruparPor(item[1], "CodLoja");
          separadoPorLoja.push(grupoDeLoja);
        });

        const periodoAnteriorSeparadoPorLoja = agruparPor(
          dataPeriodoAnterior,
          "CodLoja"
        );
        let periodoAnterior = Object.entries(periodoAnteriorSeparadoPorLoja);
        let periodoAnteriorTemp = [];
        periodoAnterior.map((item) => {
          item[1].map((item2) => {
            periodoAnteriorTemp.push(Number(item2.VlrTotal));
          });
        });

        let resumoSeparadoPorLoja = [];
        separadoPorLoja.map((item) => {
          let item2 = Object.entries(item);
          item2.map((item3) => {
            let total = 0;
            let totalPedidos = 0;
            let ano;
            let codGrupo;
            let nomeGrupo;
            let totalCusto = 0;
            let totalLucro = 0;
            let porcentagemLucro = 0;
            let codLoja = 0;
            item3[1].map((item4, index) => {
              if (index == 0) {
                ano = item4.ano;
                nomeGrupo = item4.NomeGrupo3;
                codGrupo = item4.CodGrp3;
              }
              totalPedidos += !isNaN(parseInt(item4.totalPedidos))
                ? parseInt(item4.totalPedidos)
                : 0;
              total += !isNaN(parseFloat(item4.VlrTotal))
                ? parseFloat(item4.VlrTotal)
                : 0;
              totalCusto += !isNaN(parseFloat(item4.VlrCusto))
                ? parseFloat(item4.VlrCusto)
                : 0;
              totalLucro += !isNaN(parseFloat(item4.VlrLucroBruto))
                ? parseFloat(item4.VlrLucroBruto)
                : 0;
              porcentagemLucro += !isNaN(
                parseFloat(item4.VlrLucroBrutoPorcentagem)
              )
                ? parseFloat(item4.VlrLucroBrutoPorcentagem)
                : 0;
              codLoja = item4.CodLoja;
            });
            porcentagemLucro = porcentagemLucro / item3[1].length;
            let resumoLojaAno = {
              ano: ano,
              codGrupo,
              nomeGrupo,
              total: total,
              totalPedidos: totalPedidos,
              totalCusto,
              totalLucro,
              porcentagemLucro,
              codLoja,
            };
            resumoSeparadoPorLoja.push(resumoLojaAno);
          });
        });
        var agrupado = agruparPor(resumoSeparadoPorLoja, "ano");
        grupoDeAnosArray = Object.entries(agrupado);
        preencherDadosGraficoAgrupadoGrupo(
          grupoDeAnosArray,
          tipo,
          periodoAnteriorTemp,
          dataPeriodoAnterior
        );
        setIsLoading(false);
        setDadosDisponiveis(true);
        setCarregando(false);
      } else {
        setIsLoading(false);
        setCarregando(false);
        setDadosDisponiveis(false);
      }
    } catch (error) {}
  }

  function agruparPor(objetoArray, propriedade) {
    return objetoArray.reduce(function (acc, obj) {
      let key = obj[propriedade];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  async function preencherDadosGraficoAgrupado(
    dados,
    tipo,
    anoAtual,
    anoAnterior,
    periodoAnterior
  ) {
    dados = dados.reverse();

    var label = [];
    var labelCompleta = [];
    var dataSets = [];
    var dadosTabelaAux = [];
    var dadosTabelaPeriodoAnteriorAux = [];
    var periodoAnteriorTemp = [];
    const promise = await dados.map((item, i) => {
      var dadosGrupo = [];
      item[1] = item[1].sort((a, b) => {
        return b.total - a.total;
      });

      item[1].map((itemGrupo) => {
        const codGrupo = itemGrupo.codGrupo;
        const itemPeriodoAnterior = periodoAnterior.filter(
          (a) => a.CodGrp3 === codGrupo
        );

        //periodoAnteriorTemp[codGrupo] = periodoAnteriorTemp[codGrupo] ? periodoAnteriorTemp[codGrupo] : periodoAnterior.find(a => a.CodGrp3 === codGrupo)
        var nomeFornecedor = itemGrupo.nomeFornecedor;
        if (i == 0) {
          label.push(nomeFornecedor);
          labelCompleta.push(nomeFornecedor);
          dadosGrupo.push(parseFloat(itemGrupo.total));

          if (itemPeriodoAnterior.length > 0) {
            periodoAnteriorTemp.push(
              Number(
                itemPeriodoAnterior.reduce((acc, item) => {
                  return acc + Number(item.total);
                }, 0)
              )
            );
          } else {
            periodoAnteriorTemp.push(0);
          }
        } else {
          let jaExiste = label.indexOf(nomeFornecedor);
          if (jaExiste < 0) {
            label.push(nomeFornecedor);
          }
          jaExiste = label.indexOf(nomeFornecedor);
          if (jaExiste < 0) {
            labelCompleta.push(nomeFornecedor);
            dadosGrupo.push(parseFloat(itemGrupo.total));

            if (itemPeriodoAnterior.length > 0) {
              periodoAnteriorTemp.push(
                Number(
                  itemPeriodoAnterior.reduce((acc, item) => {
                    return acc + Number(item.total);
                  }, 0)
                )
              );
            } else {
              periodoAnteriorTemp.push(0);
            }
          } else {
            dadosGrupo = insertAt(
              dadosGrupo,
              jaExiste,
              parseFloat(itemGrupo.total)
            );
            if (itemPeriodoAnterior?.length > 0) {
              periodoAnteriorTemp = insertAt(
                periodoAnteriorTemp,
                jaExiste,
                Number(
                  itemPeriodoAnterior.reduce((acc, item) => {
                    return acc + Number(item.total);
                  }, 0)
                )
              );
            } else {
              periodoAnteriorTemp = insertAt(
                periodoAnteriorTemp,
                jaExiste,
                parseFloat(0)
              );
            }
          }
        }
        dadosTabelaAux.push(itemGrupo);
        if (itemPeriodoAnterior?.length > 0) {
          dadosTabelaPeriodoAnteriorAux.push({
            CodGrp3: itemPeriodoAnterior[0].CodGrp3,
            ano: itemPeriodoAnterior[0].ano,
            nomeGrupo: itemPeriodoAnterior[0].nomeGrupo,
            porcentagemLucro: itemPeriodoAnterior[0].porcentagemLucro,
            total: Number(
              itemPeriodoAnterior.reduce((acc, item) => {
                return acc + Number(item.total);
              }, 0)
            ),
            totalCusto: Number(
              itemPeriodoAnterior.reduce((acc, item) => {
                return acc + Number(item.totalCusto);
              }, 0)
            ),
            totalLucro: Number(
              itemPeriodoAnterior.reduce((acc, item) => {
                return acc + Number(item.totalLucro);
              }, 0)
            ),
            totalPedidos: Number(
              itemPeriodoAnterior.reduce((acc, item) => {
                return acc + Number(item.totalPedidos);
              }, 0)
            ),
          });
        }
      });
      var cor =
        i == dados.length - 1
          ? "rgba(255, 194, 218, 1)"
          : i == dados.length - 2
          ? "rgba(91, 139, 252, 1)"
          : i == dados.length - 3
          ? "#5B8BFC"
          : i == dados.length - 4
          ? "#FFC2DA"
          : i == dados.length - 5
          ? ""
          : geraCorAleatoria();
      var datalabels;
      if (i == 1) {
        datalabels = {
          anchor: "end",
          display: true,
          color: cor,
          align: "end",
          // rotation: -8,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else if (i == 0) {
        datalabels = {
          anchor: "end",
          display: true,
          color: cor,
          align: "end",
          // rotation: -8,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else {
        datalabels = {
          formatter: function (value, context) {
            return "";
          },
        };
      }

      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
        barThickness: "flex",
      });
    });
    dataSets.push({
      label: "Periodo anterior",
      data: periodoAnteriorTemp,
      backgroundColor: "#FFDB8A",
      datalabels: {
        formatter: function (value, context) {
          return parseFloat(value).toLocaleString("pt-BR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        },
      },
    });

    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "nomeFornecedor");
    dadosTabelaAux = Object.entries(dadosTabelaAux);
    dadosTabelaPeriodoAnteriorAux = agruparPor(
      dadosTabelaPeriodoAnteriorAux,
      "nomeGrupo"
    );
    dadosTabelaPeriodoAnteriorAux = Object.entries(
      dadosTabelaPeriodoAnteriorAux
    );
    preencherDadosTabela(
      dadosTabelaAux,
      anoAtual,
      anoAnterior,
      tipo,
      periodoAnterior
    );
    setDadosGraficoAgrupado(data);
  }

  function insertAt(array, index, element) {
    if (array.length == index && array.length == 0) {
      array.push(0);
      array.splice(index, 1, element);
      return array;
    }
    if (array.length < index) {
      do {
        array.push(0);
      } while (array.length < index);
      array.splice(index, 1, element);
      return array;
    } else {
      array.splice(index, 1, element);
      return array;
    }
  }

  async function preencherDadosGraficoAgrupadoGrupo(
    dados,
    tipo,
    periodoAnterior,
    periodoAnteriorAux
  ) {
    dados = dados.reverse();
    let label = [];
    let labelCompleta = [];
    let dataSets = [];
    let dadosTabelaAux = [];
    let auxDataSets = [];

    const promise = await dados.map((item, i) => {
      let dadosGrupo = [];
      let maiorDataSet = 0;
      auxDataSets.map((item) => {
        maiorDataSet = maiorDataSet < item.length ? item.length : maiorDataSet;
      });

      for (let i = 0; i < maiorDataSet.length; i++) {
        dadosGrupo.push(0);
      }

      item[1].map((itemGrupo) => {
        let nomeLoja = converterLoja(itemGrupo.codLoja);
        if (i == 0) {
          label.push(nomeLoja);
          labelCompleta.push(nomeLoja);
          dadosGrupo.push(parseFloat(itemGrupo.total));
        } else {
          let jaExiste = label.indexOf(nomeLoja);

          if (jaExiste < 0) {
            label.push(nomeLoja);
          }
          jaExiste = label.indexOf(nomeLoja);
          if (jaExiste < 0) {
            labelCompleta.push(nomeLoja);
            dadosGrupo.push(parseFloat(itemGrupo.total));
          } else {
            dadosGrupo = insertAt(
              dadosGrupo,
              jaExiste,
              parseFloat(itemGrupo.total)
            );
          }
        }

        dadosTabelaAux.push(itemGrupo);
      });
      var cor =
        i == dados.length - 1
          ? "rgba(255, 194, 218, 1)"
          : i == dados.length - 2
          ? "rgba(91, 139, 252, 1)"
          : i == dados.length - 3
          ? "#5B8BFC"
          : i == dados.length - 4
          ? "#FFC2DA"
          : i == dados.length - 5
          ? ""
          : geraCorAleatoria();
      var datalabels;
      if (item[0] == auxAnoAtual) {
        datalabels = {
          anchor: "end",
          display: true,
          color: "#000",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else if (item[0] == auxAnoAnterior) {
        datalabels = {
          anchor: "start",
          display: true,
          color: cor,
          align: "start",
          // rotation: -5,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else {
        datalabels = {
          formatter: function (value, context) {
            return "";
          },
        };
      }
      auxDataSets.push(dadosGrupo);
      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
      });
    });

    dataSets.push({
      label: "Periodo anterior",
      data: periodoAnterior,
      backgroundColor: "#FFDB8A",
      datalabels: {
        formatter: function (value, context) {
          return parseFloat(value).toLocaleString("pt-BR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        },
      },
    });

    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "codLoja");
    dadosTabelaAux = Object.entries(dadosTabelaAux);
    preencherDadosTabelaGrupo(dadosTabelaAux, periodoAnteriorAux);
    setDadosGraficoAgrupado1(data);
    setFiltrosAtivosLojasTexto(" Fornecedor " + grupoAtivo);
    setNivelAtivoDoGrafico(1);
  }

  function geraCorAleatoria() {
    let hexadecimais = "0123456789ABCDEF";
    let cor = "#";

    for (let i = 0; i < 6; i++) {
      cor += hexadecimais[Math.floor(Math.random() * 16)];
    }
    return cor;
  }

  function preencherDadosTabela(
    dados,
    anoAtual,
    anoAnterior,
    tipo,
    dadosPeriodoAnterior = false
  ) {
    let dadosTabela = [];
    let quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;
    let qtdAnoAtualResumo = 0,
      vlrAnoAtualResumo = 0,
      custoTotalAnoAtualResumo = 0,
      custoTotalAnoAnteriorResumo = 0,
      qtdAnoAnteriorResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      desempenhoPAResumo = 0,
      lucroBrutoTotalAnoAtualResumo = 0,
      lucroBrutoTotalAnoAnteriorResumo = 0,
      lucroPorcentagemAnoAtualResumo = 0,
      lucroPorcentagemAnoAnteriorResumo = 0,
      variacaoResumo = 0,
      qtdPeriodoAnteriorResumo = 0,
      lucroPeriodoAnteriorResumo = 0,
      porcentagemLucroPeriodoAnteriorResumo = 0,
      vlrTotalPeriodoAnteriorResumo = 0,
      custoPeriodoAnteriorResumo = 0; // = 0;

    if (dados.length > 0) {
      dados.map((item) => {
        let grupo = item[0];
        let qtdAnoAtual = 0,
          qtdAnoAnterior = 0,
          vlrAnoAtual = 0,
          vlrTotalAnoAnterior = 0,
          custoTotalAnoAtual = 0,
          custoTotalAnoAnterior = 0,
          lucroBrutoTotalAnoAtual = 0,
          lucroBrutoTotalAnoAnterior = 0,
          lucroPorcentagemAnoAtual = 0,
          desempPeriodoAnterior = 0,
          lucroPorcentagemAnoAnterior = 0,
          variacao;

        const periodoAnteriorItem = dadosPeriodoAnterior?.filter(
          (item) => item?.nomeGrupo === grupo
        );

        let vlrPeriodoAnterior = periodoAnteriorItem?.length
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.total);
            }, 0)
          : 0;
        let qtdPeriodoAnterior = periodoAnteriorItem?.length
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.totalPedidos);
            }, 0)
          : 0;
        let custoPeriodoAnterior = periodoAnteriorItem?.length
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.totalCusto);
            }, 0)
          : 0;
        let lucroPeriodoAnterior = periodoAnteriorItem?.length
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.totalLucro);
            }, 0)
          : 0;
        let porcentagemLucroPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem?.porcentagemLucro)
          : 0;

        qtdPeriodoAnteriorResumo += qtdPeriodoAnterior;
        lucroPeriodoAnteriorResumo += lucroPeriodoAnterior;
        porcentagemLucroPeriodoAnteriorResumo +=
          porcentagemLucroPeriodoAnterior;
        vlrTotalPeriodoAnteriorResumo += vlrPeriodoAnterior;
        custoPeriodoAnteriorResumo += custoPeriodoAnterior;

        let separaDoisUltimosAnos = [];
        if (item[1][0]) {
          separaDoisUltimosAnos.push(item[1][0]);
        }
        if (item[1][1]) {
          separaDoisUltimosAnos.push(item[1][1]);
        } else {
          let ano = (parseInt(item[1][0].ano) - 1).toString();
          let porcentagemLucro = 0;
          let total = 0;
          let totalCusto = 0;
          let totalLucro = 0;
          let totalPedidos = 0;
          separaDoisUltimosAnos.push({
            ano,
            total,
            totalPedidos,
            totalCusto,
            totalLucro,
            porcentagemLucro,
          });
        }

        separaDoisUltimosAnos.map((item2, index2) => {
          if (item2.ano == anoAtual) {
            qtdAnoAtual = !isNaN(parseInt(item2.totalPedidos))
              ? parseInt(item2.totalPedidos)
              : 0;
            vlrAnoAtual = !isNaN(parseFloat(parseFloat(item2.total).toFixed(2)))
              ? parseFloat(parseFloat(item2.total).toFixed(2))
              : 0;
            custoTotalAnoAtual = !isNaN(
              parseFloat(parseFloat(item2.totalCusto).toFixed(2))
            )
              ? parseFloat(parseFloat(item2.totalCusto).toFixed(2))
              : 0;
            lucroBrutoTotalAnoAtual = !isNaN(
              parseFloat(parseFloat(item2.total - item2.totalCusto).toFixed(2))
            )
              ? parseFloat(
                  parseFloat(item2.total - item2.totalCusto).toFixed(2)
                )
              : 0;
            lucroPorcentagemAnoAtual = !isNaN(
              parseFloat(parseFloat(item2.porcentagemLucro).toFixed(2))
            )
              ? parseFloat(
                  parseFloat(
                    Number((item2.total - item2.totalCusto) / item2.total) * 100
                  ).toFixed(2)
                )
              : 0;
            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            custoTotalAnoAtualResumo += custoTotalAnoAtual;
            lucroBrutoTotalAnoAtualResumo += lucroBrutoTotalAnoAtual;
            lucroPorcentagemAnoAtualResumo += lucroPorcentagemAnoAtual;
            if (qtdAnoAtual > 0) {
              quantidadeLojasAnoAtual++;
            }
          } else if (item2.ano == anoAnterior) {
            qtdAnoAnterior = !isNaN(parseInt(item2.totalPedidos))
              ? parseInt(item2.totalPedidos)
              : 0;
            vlrTotalAnoAnterior = !isNaN(
              parseFloat(parseFloat(item2.total).toFixed(2))
            )
              ? parseFloat(parseFloat(item2.total).toFixed(2))
              : 0;
            custoTotalAnoAnterior = !isNaN(
              parseFloat(parseFloat(item2.totalCusto).toFixed(2))
            )
              ? parseFloat(parseFloat(item2.totalCusto).toFixed(2))
              : 0;
            lucroBrutoTotalAnoAnterior = !isNaN(
              parseFloat(parseFloat(item2.totalLucro).toFixed(2))
            )
              ? parseFloat(
                  parseFloat(item2.total - item2.totalCusto).toFixed(2)
                )
              : 0;
            lucroPorcentagemAnoAnterior = !isNaN(
              parseFloat(parseFloat(item2.porcentagemLucro).toFixed(2))
            )
              ? parseFloat(
                  parseFloat(
                    Number((item2.total - item2.totalCusto) / item2.total) * 100
                  ).toFixed(2)
                )
              : 0;

            qtdAnoAnteriorResumo += qtdAnoAnterior;
            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            custoTotalAnoAnteriorResumo += custoTotalAnoAnterior;
            lucroBrutoTotalAnoAnteriorResumo += lucroBrutoTotalAnoAnterior;
            lucroPorcentagemAnoAnteriorResumo += lucroPorcentagemAnoAnterior;
            if (qtdAnoAnterior > 0) {
              quantidadeLojasAnoAnterior++;
            }
          }
        });
        if (qtdAnoAnterior > 0) {
          variacao = parseFloat(
            ((vlrAnoAtual / vlrTotalAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          variacao = 0;
        }

        if (qtdPeriodoAnterior > 0) {
          desempPeriodoAnterior = parseFloat(
            ((vlrAnoAtual / vlrPeriodoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempPeriodoAnterior = 0;
        }

        const ticketMedioAnoAtual = qtdAnoAtual ? vlrAnoAtual / qtdAnoAtual : 0;
        const ticketMedioAnoAnterior = qtdAnoAnterior
          ? vlrTotalAnoAnterior / qtdAnoAnterior
          : 0;
        const ticketMedioPeriodoAnterior = qtdPeriodoAnterior
          ? vlrPeriodoAnterior / qtdPeriodoAnterior
          : 0;

        dadosTabela.push({
          grupo: grupo,
          qtdAnoAtual: qtdAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrAnoAtual: vlrAnoAtual,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          custoTotalAtual: custoTotalAnoAtual,
          custoTotalAnterior: custoTotalAnoAnterior,
          lucroBrutoAtual: lucroBrutoTotalAnoAtual,
          lucroBrutoAnterior: lucroBrutoTotalAnoAnterior,
          lucro: lucroPorcentagemAnoAtual,
          lucroAnterior: lucroPorcentagemAnoAnterior,
          variacao: variacao,
          vlrPeriodoAnterior: vlrPeriodoAnterior,
          desempPeriodoAnterior: desempPeriodoAnterior,
          qtdPeriodoAnterior: qtdPeriodoAnterior,
          lucroPeriodoAnterior: lucroPeriodoAnterior,
          custoPeriodoAnterior: custoPeriodoAnterior,
          porcentagemLucroPeriodoAnterior: porcentagemLucroPeriodoAnterior,
          ticketMedioAnoAtual,
          ticketMedioAnoAnterior,
          ticketMedioPeriodoAnterior,
        });
      });
    }
    lucroPorcentagemAnoAtualResumo = parseFloat(
      (lucroPorcentagemAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    lucroPorcentagemAnoAnteriorResumo = parseFloat(
      (lucroPorcentagemAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(
        2
      )
    );
    variacaoResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalAnoAnteriorResumo - 1) * 100).toFixed(2)
    );

    desempenhoPAResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalPeriodoAnteriorResumo - 1) * 100).toFixed(2)
    );

    const ticketMedioAnoAtualResumo = qtdAnoAtualResumo
      ? vlrAnoAtualResumo / qtdAnoAtualResumo
      : 0;
    const ticketMedioAnoAnteriorResumo = qtdAnoAnteriorResumo
      ? vlrTotalAnoAnteriorResumo / qtdAnoAnteriorResumo
      : 0;
    const ticketMedioPeriodoAnteriorResumo = qtdPeriodoAnteriorResumo
      ? vlrTotalPeriodoAnteriorResumo / qtdPeriodoAnteriorResumo
      : 0;

    lucroBrutoTotalAnoAtualResumo =
      vlrAnoAtualResumo - custoTotalAnoAtualResumo;
    lucroBrutoTotalAnoAnteriorResumo =
      vlrTotalAnoAnteriorResumo - custoTotalAnoAnteriorResumo;
    lucroPeriodoAnteriorResumo =
      vlrTotalPeriodoAnteriorResumo - custoPeriodoAnteriorResumo;

    lucroPorcentagemAnoAtualResumo = vlrAnoAtualResumo
      ? (lucroBrutoTotalAnoAtualResumo / vlrAnoAtualResumo) * 100
      : 0;
    lucroPorcentagemAnoAnteriorResumo = vlrTotalAnoAnteriorResumo
      ? (lucroBrutoTotalAnoAnteriorResumo / vlrTotalAnoAnteriorResumo) * 100
      : 0;
    porcentagemLucroPeriodoAnteriorResumo = vlrTotalPeriodoAnteriorResumo
      ? (lucroPeriodoAnteriorResumo / vlrTotalPeriodoAnteriorResumo) * 100
      : 0;

    dadosTabela.push({
      grupo: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      custoTotalAtual: custoTotalAnoAtualResumo,
      custoTotalAnterior: custoTotalAnoAnteriorResumo,
      lucroBrutoAtual: lucroBrutoTotalAnoAtualResumo,
      lucroBrutoAnterior: lucroBrutoTotalAnoAnteriorResumo,
      lucro: lucroPorcentagemAnoAtualResumo,
      lucroAnterior: lucroPorcentagemAnoAnteriorResumo,
      variacao: variacaoResumo,
      vlrPeriodoAnterior: vlrTotalPeriodoAnteriorResumo,
      qtdPeriodoAnterior: qtdPeriodoAnteriorResumo,
      lucroPeriodoAnterior: lucroPeriodoAnteriorResumo,
      custoPeriodoAnterior: custoPeriodoAnteriorResumo,
      porcentagemLucroPeriodoAnterior: porcentagemLucroPeriodoAnteriorResumo,
      ticketMedioAnoAtual: ticketMedioAnoAtualResumo,
      ticketMedioAnoAnterior: ticketMedioAnoAnteriorResumo,
      desempPeriodoAnterior: desempenhoPAResumo,
      ticketMedioPeriodoAnterior: ticketMedioPeriodoAnteriorResumo,
    });
    setDadosParaTabela(dadosTabela);

    setAuxDadosParaTabela(dadosTabela);
  }

  function preencherDadosTabelaGrupo(dados, dadosPeriodoAnterior) {
    // var anoAtual = 0;
    var dadosTabela = [];
    var quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;
    let qtdAnoAtualResumo = 0,
      vlrAnoAtualResumo = 0,
      custoTotalAnoAtualResumo = 0,
      custoTotalAnoAnteriorResumo = 0,
      qtdAnoAnteriorResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      vlrMedioAnoAnteriorResumo = 0,
      desempenhoPAResumo = 0,
      lucroBrutoTotalAnoAtualResumo = 0,
      lucroBrutoTotalAnoAnteriorResumo = 0,
      lucroPorcentagemAnoAtualResumo = 0,
      lucroPorcentagemAnoAnteriorResumo = 0,
      desempenhoResumo = 0,
      variacaoResumo = 0,
      qtdPeriodoAnteriorResumo = 0,
      lucroPeriodoAnteriorResumo = 0,
      porcentagemLucroPeriodoAnteriorResumo = 0,
      vlrTotalPeriodoAnteriorResumo = 0,
      custoPeriodoAnteriorResumo = 0;

    if (dados.length > 0) {
      dados.map((item) => {
        let loja = item[0];
        let qtdAnoAtual = 0,
          qtdAnoAnterior = 0,
          vlrAnoAtual = 0,
          vlrTotalAnoAnterior = 0,
          custoTotalAnoAtual = 0,
          desempPeriodoAnterior = 0,
          custoTotalAnoAnterior = 0,
          lucroBrutoTotalAnoAtual = 0,
          lucroBrutoTotalAnoAnterior = 0,
          lucroPorcentagemAnoAtual = 0,
          lucroPorcentagemAnoAnterior = 0,
          variacao = 0;

        const periodoAnteriorItem = dadosPeriodoAnterior?.find(
          (item) => item?.CodLoja === loja
        );

        let vlrPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem?.VlrTotal)
          : 0;
        let qtdPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem?.totalPedidos)
          : 0;
        let custoPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem?.VlrCusto)
          : 0;
        let lucroPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem?.VlrLucroBruto)
          : 0;
        let porcentagemLucroPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem?.VlrLucroBrutoPorcentagem)
          : 0;

        qtdPeriodoAnteriorResumo += qtdPeriodoAnterior;
        lucroPeriodoAnteriorResumo += lucroPeriodoAnterior;
        porcentagemLucroPeriodoAnteriorResumo += lucroPeriodoAnterior;
        vlrTotalPeriodoAnteriorResumo += vlrPeriodoAnterior;
        custoPeriodoAnteriorResumo += custoPeriodoAnterior;

        var separaDoisUltimosAnos = [];
        if (item[1][0]) {
          separaDoisUltimosAnos.push(item[1][0]);
        }
        if (item[1][1]) {
          separaDoisUltimosAnos.push(item[1][1]);
        } else {
          let ano = (parseInt(item[1][0].ano) - 1).toString();
          let porcentagemLucro = 0;
          let total = 0;
          let totalCusto = 0;
          let totalLucro = 0;
          let totalPedidos = 0;
          separaDoisUltimosAnos.push({
            ano,
            total,
            totalPedidos,
            totalCusto,
            totalLucro,
            porcentagemLucro,
          });
        }

        separaDoisUltimosAnos.map((item2, index2) => {
          //'ano': ano, codGrupo, nomeGrupo, 'total': total, 'totalPedidos': totalPedidos, totalCusto, totalLucro, porcentagemLucro
          if (item2.ano == auxAnoAtual) {
            qtdAnoAtual = !isNaN(parseInt(item2.totalPedidos))
              ? parseInt(item2.totalPedidos)
              : 0;
            vlrAnoAtual = !isNaN(parseFloat(parseFloat(item2.total).toFixed(2)))
              ? parseFloat(parseFloat(item2.total).toFixed(2))
              : 0;
            custoTotalAnoAtual = !isNaN(
              parseFloat(parseFloat(item2.totalCusto).toFixed(2))
            )
              ? parseFloat(parseFloat(item2.totalCusto).toFixed(2))
              : 0;
            lucroBrutoTotalAnoAtual = !isNaN(
              parseFloat(parseFloat(item2.total - item2.totalCusto).toFixed(2))
            )
              ? parseFloat(parseFloat(item2.totalLucro).toFixed(2))
              : 0;
            lucroPorcentagemAnoAtual = !isNaN(
              parseFloat(parseFloat(item2.porcentagemLucro).toFixed(2))
            )
              ? parseFloat(
                  parseFloat(
                    Number((item2.total - item2.totalCusto) / item2.total) * 100
                  ).toFixed(2)
                )
              : 0;

            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            // vlrMedioAnoAtualResumo += vlrMedioAnoAtual;
            custoTotalAnoAtualResumo += custoTotalAnoAtual;
            lucroBrutoTotalAnoAtualResumo += lucroBrutoTotalAnoAtual;
            lucroPorcentagemAnoAtualResumo += lucroPorcentagemAnoAtual;
            quantidadeLojasAnoAtual++;
          } else if (item2.ano == auxAnoAnterior) {
            qtdAnoAnterior = !isNaN(parseInt(item2.totalPedidos))
              ? parseInt(item2.totalPedidos)
              : 0;
            vlrTotalAnoAnterior = !isNaN(
              parseFloat(parseFloat(item2.total).toFixed(2))
            )
              ? parseFloat(parseFloat(item2.total).toFixed(2))
              : 0;
            custoTotalAnoAnterior = !isNaN(
              parseFloat(parseFloat(item2.totalCusto).toFixed(2))
            )
              ? parseFloat(parseFloat(item2.totalCusto).toFixed(2))
              : 0;
            lucroBrutoTotalAnoAnterior = !isNaN(
              parseFloat(parseFloat(item2.totalLucro).toFixed(2))
            )
              ? parseFloat(
                  parseFloat(item2.total - item2.totalCusto).toFixed(2)
                )
              : 0;
            lucroPorcentagemAnoAnterior = !isNaN(
              parseFloat(parseFloat(item2.porcentagemLucro).toFixed(2))
            )
              ? parseFloat(
                  parseFloat(
                    Number((item2.total - item2.totalCusto) / item2.total) * 100
                  ).toFixed(2)
                )
              : 0;

            qtdAnoAnteriorResumo += qtdAnoAnterior;
            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            // vlrMedioAnoAnteriorResumo += vlrMedioAnoAnterior;
            custoTotalAnoAnteriorResumo += custoTotalAnoAnterior;
            lucroBrutoTotalAnoAnteriorResumo += lucroBrutoTotalAnoAnterior;
            lucroPorcentagemAnoAnteriorResumo += lucroPorcentagemAnoAnterior;
            quantidadeLojasAnoAnterior++;
          }
        });
        if (qtdAnoAnterior > 0) {
          variacao = parseFloat(
            ((vlrAnoAtual / vlrTotalAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          variacao = 0;
        }

        if (qtdPeriodoAnterior > 0) {
          desempPeriodoAnterior = parseFloat(
            ((vlrAnoAtual / vlrPeriodoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempPeriodoAnterior = 0;
        }

        const ticketMedioAnoAtual = qtdAnoAtual ? vlrAnoAtual / qtdAnoAtual : 0;
        const ticketMedioAnoAnterior = qtdAnoAnterior
          ? vlrTotalAnoAnterior / qtdAnoAnterior
          : 0;
        const ticketMedioPeriodoAnterior = qtdPeriodoAnterior
          ? vlrPeriodoAnterior / qtdPeriodoAnterior
          : 0;

        dadosTabela.push({
          loja: loja,
          qtdAnoAtual: qtdAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrAnoAtual: vlrAnoAtual,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          custoTotalAtual: custoTotalAnoAtual,
          custoTotalAnterior: custoTotalAnoAnterior,
          lucroBrutoAtual: lucroBrutoTotalAnoAtual,
          lucroBrutoAnterior: lucroBrutoTotalAnoAnterior,
          lucro: lucroPorcentagemAnoAtual,
          lucroAnterior: lucroPorcentagemAnoAnterior,
          desempPeriodoAnterior: desempPeriodoAnterior,
          variacao: variacao,
          vlrPeriodoAnterior: vlrPeriodoAnterior,
          qtdPeriodoAnterior: qtdPeriodoAnterior,
          lucroPeriodoAnterior: lucroPeriodoAnterior,
          custoPeriodoAnterior: custoPeriodoAnterior,
          porcentagemLucroPeriodoAnterior: porcentagemLucroPeriodoAnterior,
          ticketMedioAnoAtual,
          ticketMedioAnoAnterior,
          ticketMedioPeriodoAnterior,
        });
      });
    }

    lucroPorcentagemAnoAtualResumo = parseFloat(
      (lucroPorcentagemAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    lucroPorcentagemAnoAnteriorResumo = parseFloat(
      (lucroPorcentagemAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(
        2
      )
    );
    variacaoResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalAnoAnteriorResumo - 1) * 100).toFixed(2)
    );

    desempenhoPAResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalPeriodoAnteriorResumo - 1) * 100).toFixed(2)
    );

    const ticketMedioAnoAtualResumo = qtdAnoAtualResumo
      ? vlrAnoAtualResumo / qtdAnoAtualResumo
      : 0;
    const ticketMedioAnoAnteriorResumo = qtdAnoAnteriorResumo
      ? vlrTotalAnoAnteriorResumo / qtdAnoAnteriorResumo
      : 0;
    const ticketMedioPeriodoAnteriorResumo = qtdPeriodoAnteriorResumo
      ? vlrTotalPeriodoAnteriorResumo / qtdPeriodoAnteriorResumo
      : 0;

    lucroBrutoTotalAnoAtualResumo =
      vlrAnoAtualResumo - custoTotalAnoAtualResumo;
    lucroBrutoTotalAnoAnteriorResumo =
      vlrTotalAnoAnteriorResumo - custoTotalAnoAnteriorResumo;
    lucroPeriodoAnteriorResumo =
      vlrTotalPeriodoAnteriorResumo - custoPeriodoAnteriorResumo;

    lucroPorcentagemAnoAtualResumo = vlrAnoAtualResumo
      ? (lucroBrutoTotalAnoAtualResumo / vlrAnoAtualResumo) * 100
      : 0;
    lucroPorcentagemAnoAnteriorResumo = vlrTotalAnoAnteriorResumo
      ? (lucroBrutoTotalAnoAnteriorResumo / vlrTotalAnoAnteriorResumo) * 100
      : 0;
    porcentagemLucroPeriodoAnteriorResumo = vlrTotalPeriodoAnteriorResumo
      ? (lucroPeriodoAnteriorResumo / vlrTotalPeriodoAnteriorResumo) * 100
      : 0;

    dadosTabela.push({
      loja: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      custoTotalAtual: custoTotalAnoAtualResumo,
      custoTotalAnterior: custoTotalAnoAnteriorResumo,
      lucroBrutoAtual: lucroBrutoTotalAnoAtualResumo,
      lucroBrutoAnterior: lucroBrutoTotalAnoAnteriorResumo,
      lucro: lucroPorcentagemAnoAtualResumo,
      lucroAnterior: lucroPorcentagemAnoAnteriorResumo,
      desempPeriodoAnterior: desempenhoPAResumo,
      variacao: variacaoResumo,
      vlrPeriodoAnterior: vlrTotalPeriodoAnteriorResumo,
      qtdPeriodoAnterior: qtdPeriodoAnteriorResumo,
      lucroPeriodoAnterior: lucroPeriodoAnteriorResumo,
      custoPeriodoAnterior: custoPeriodoAnteriorResumo,
      porcentagemLucroPeriodoAnterior: porcentagemLucroPeriodoAnteriorResumo,
      ticketMedioAnoAtual: ticketMedioAnoAtualResumo,
      ticketMedioAnoAnterior: ticketMedioAnoAnteriorResumo,
      ticketMedioPeriodoAnterior: ticketMedioPeriodoAnteriorResumo,
    });

    setDadosParaTabela(dadosTabela);
    setDadosParaTabelaLoja(dadosTabela);
  }

  function preencherDadosTabelaMes(dados) {
    let dadosTabela = [];
    let quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;
    let qtdAnoAtualResumo = 0,
      vlrAnoAtualResumo = 0,
      custoTotalAnoAtualResumo = 0,
      custoTotalAnoAnteriorResumo = 0,
      qtdAnoAnteriorResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      lucroBrutoTotalAnoAtualResumo = 0,
      lucroBrutoTotalAnoAnteriorResumo = 0,
      lucroPorcentagemAnoAtualResumo = 0,
      lucroPorcentagemAnoAnteriorResumo = 0,
      variacaoResumo = 0;
    if (dados.length > 0) {
      dados.map((item) => {
        let mes = parseInt(item[0]);
        let qtdAnoAtual = 0,
          qtdAnoAnterior = 0,
          vlrAnoAtual = 0,
          vlrTotalAnoAnterior = 0,
          custoTotalAnoAtual = 0,
          custoTotalAnoAnterior = 0,
          lucroBrutoTotalAnoAtual = 0,
          lucroBrutoTotalAnoAnterior = 0,
          lucroPorcentagemAnoAtual = 0,
          lucroPorcentagemAnoAnterior = 0,
          variacao = 0;
        item[1].map((item2, index2) => {
          if (item2.ano == auxAnoAtual) {
            qtdAnoAtual = !isNaN(parseInt(item2.totalPedidos))
              ? parseInt(item2.totalPedidos)
              : 0;
            vlrAnoAtual = !isNaN(
              parseFloat(parseFloat(item2.VlrTotal).toFixed(2))
            )
              ? parseFloat(parseFloat(item2.VlrTotal).toFixed(2))
              : 0;
            custoTotalAnoAtual = !isNaN(
              parseFloat(parseFloat(item2.VlrCusto).toFixed(2))
            )
              ? parseFloat(parseFloat(item2.VlrCusto).toFixed(2))
              : 0;
            lucroBrutoTotalAnoAtual = !isNaN(
              parseFloat(parseFloat(item2.total - item2.totalCusto).toFixed(2))
            )
              ? parseFloat(parseFloat(item2.VlrLucroBruto).toFixed(2))
              : 0;
            lucroPorcentagemAnoAtual = !isNaN(
              parseFloat(parseFloat(item2.total - item2.totalCusto).toFixed(2))
            )
              ? parseFloat(
                  parseFloat(
                    Number((item2.total - item2.totalCusto) / item2.total) * 100
                  ).toFixed(2)
                )
              : 0;
            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            custoTotalAnoAtualResumo += custoTotalAnoAtual;
            lucroBrutoTotalAnoAtualResumo += lucroBrutoTotalAnoAtual;
            lucroPorcentagemAnoAtualResumo += lucroPorcentagemAnoAtual;
            if (qtdAnoAtual > 0) {
              quantidadeLojasAnoAtual++;
            }
          } else if (item2.ano == auxAnoAnterior) {
            qtdAnoAnterior = !isNaN(parseInt(item2.totalPedidos))
              ? parseInt(item2.totalPedidos)
              : 0;
            vlrTotalAnoAnterior = !isNaN(
              parseFloat(parseFloat(item2.VlrTotal).toFixed(2))
            )
              ? parseFloat(parseFloat(item2.VlrTotal).toFixed(2))
              : 0;
            custoTotalAnoAnterior = !isNaN(
              parseFloat(parseFloat(item2.VlrCusto).toFixed(2))
            )
              ? parseFloat(parseFloat(item2.VlrCusto).toFixed(2))
              : 0;
            lucroBrutoTotalAnoAnterior = !isNaN(
              parseFloat(parseFloat(item2.total - item2.totalCusto).toFixed(2))
            )
              ? parseFloat(parseFloat(item2.VlrLucroBruto).toFixed(2))
              : 0;
            lucroPorcentagemAnoAnterior = !isNaN(
              parseFloat(parseFloat(item2.VlrLucroBrutoPorcentagem).toFixed(2))
            )
              ? parseFloat(
                  parseFloat(
                    Number((item2.total - item2.totalCusto) / item2.total) * 100
                  ).toFixed(2)
                )
              : 0;

            qtdAnoAnteriorResumo += qtdAnoAnterior;
            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            custoTotalAnoAnteriorResumo += custoTotalAnoAnterior;
            lucroBrutoTotalAnoAnteriorResumo += lucroBrutoTotalAnoAnterior;
            lucroPorcentagemAnoAnteriorResumo += lucroPorcentagemAnoAnterior;
            if (qtdAnoAnterior > 0) {
              quantidadeLojasAnoAnterior++;
            }
          }
        });
        if (vlrTotalAnoAnterior > 0) {
          variacao = parseFloat(
            ((vlrAnoAtual / vlrTotalAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          variacao = 0;
        }

        const ticketMedioAnoAtual = qtdAnoAtual ? vlrAnoAtual / qtdAnoAtual : 0;
        const ticketMedioAnoAnterior = qtdAnoAnterior
          ? vlrTotalAnoAnterior / qtdAnoAnterior
          : 0;
        dadosTabela.push({
          mes: mes,
          qtdAnoAtual: qtdAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrAnoAtual: vlrAnoAtual,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          custoTotalAtual: custoTotalAnoAtual,
          custoTotalAnterior: custoTotalAnoAnterior,
          lucroBrutoAtual: lucroBrutoTotalAnoAtual,
          lucroBrutoAnterior: lucroBrutoTotalAnoAnterior,
          lucro: lucroPorcentagemAnoAtual,
          lucroAnterior: lucroPorcentagemAnoAnterior,
          variacao: variacao,
          ticketMedioAnoAtual,
          ticketMedioAnoAnterior,
        });
      });
    }
    lucroPorcentagemAnoAtualResumo = parseFloat(
      (lucroPorcentagemAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    lucroPorcentagemAnoAnteriorResumo = parseFloat(
      (lucroPorcentagemAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(
        2
      )
    );
    variacaoResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalAnoAnteriorResumo - 1) * 100).toFixed(2)
    );

    const ticketMedioAnoAtualResumo = qtdAnoAtualResumo
      ? vlrAnoAtualResumo / qtdAnoAtualResumo
      : 0;
    const ticketMedioAnoAnteriorResumo = qtdAnoAnteriorResumo
      ? vlrTotalAnoAnteriorResumo / qtdAnoAnteriorResumo
      : 0;

    lucroBrutoTotalAnoAtualResumo =
      vlrAnoAtualResumo - custoTotalAnoAtualResumo;
    lucroBrutoTotalAnoAnteriorResumo =
      vlrTotalAnoAnteriorResumo - custoTotalAnoAnteriorResumo;

    lucroPorcentagemAnoAtualResumo = vlrAnoAtualResumo
      ? (lucroBrutoTotalAnoAtualResumo / vlrAnoAtualResumo) * 100
      : 0;
    lucroPorcentagemAnoAnteriorResumo = vlrTotalAnoAnteriorResumo
      ? (lucroBrutoTotalAnoAnteriorResumo / vlrTotalAnoAnteriorResumo) * 100
      : 0;

    dadosTabela.push({
      mes: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      custoTotalAtual: custoTotalAnoAtualResumo,
      custoTotalAnterior: custoTotalAnoAnteriorResumo,
      lucroBrutoAtual: lucroBrutoTotalAnoAtualResumo,
      lucroBrutoAnterior: lucroBrutoTotalAnoAnteriorResumo,
      lucro: lucroPorcentagemAnoAtualResumo,
      lucroAnterior: lucroPorcentagemAnoAnteriorResumo,
      variacao: variacaoResumo,
      ticketMedioAnoAtual: ticketMedioAnoAtualResumo,
      ticketMedioAnoAnterior: ticketMedioAnoAnteriorResumo,
    });

    setDadosParaTabela(dadosTabela);
  }

  async function preencherDadosGraficoAgrupadoGrupoMes(loja) {
    setCodLojaAtiva(loja);
    let codLoja = converterNomeLoja(loja);
    let dados = dadosGeraisGrupo;
    dados = dados.filter((a) => {
      return a.CodLoja == codLoja ? true : false;
    });
    let grupoDeAnos = agruparPor(dados, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    dados = grupoDeAnosArray;
    dados = dados.reverse();
    let label = [];
    let labelCompleta = [];
    let dataSets = [];
    let dadosTabelaAux = [];
    let auxDataSets = [];
    const promise = await dados.map((item, i) => {
      let dadosGrupo = [];
      let maiorDataSet = 0;
      auxDataSets.map((item) => {
        maiorDataSet = maiorDataSet < item.length ? item.length : maiorDataSet;
      });
      for (let i = 0; i < maiorDataSet.length; i++) {
        dadosGrupo.push(0);
      }
      item[1] = item[1].sort((a, b) => {
        return a.mes - b.mes;
      });
      item[1].map((itemGrupo) => {
        let nomeMes = converterMes(itemGrupo.mes);
        if (i == 0) {
          label.push(nomeMes);
          labelCompleta.push(nomeMes);
          dadosGrupo.push(parseFloat(itemGrupo.VlrTotal));
        } else {
          let jaExiste = label.indexOf(nomeMes);
          if (jaExiste < 0) {
            label.push(nomeMes);
            labelCompleta.push(nomeMes);
          }
          jaExiste = label.indexOf(nomeMes);
          if (jaExiste < 0) {
            dadosGrupo.push(parseFloat(itemGrupo.VlrTotal));
          } else {
            dadosGrupo = insertAt(
              dadosGrupo,
              jaExiste,
              parseFloat(itemGrupo.VlrTotal)
            );
          }
        }
        dadosTabelaAux.push(itemGrupo);
      });
      let cor =
        i == dados.length - 1
          ? "rgba(255, 194, 218, 1)"
          : i == dados.length - 2
          ? "rgba(91, 139, 252, 1)"
          : i == dados.length - 3
          ? "#5B8BFC"
          : i == dados.length - 4
          ? "#FFC2DA"
          : i == dados.length - 5
          ? ""
          : geraCorAleatoria();
      let datalabels;
      if (item[0] == auxAnoAtual) {
        datalabels = {
          anchor: "end",
          display: true,
          color: "#000",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else if (item[0] == auxAnoAnterior) {
        datalabels = {
          anchor: "start",
          display: true,
          color: cor,
          align: "start",
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else {
        datalabels = {
          formatter: function (value, context) {
            return "";
          },
        };
      }
      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
        barThickness: "flex",
      });
    });
    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "mes");
    dadosTabelaAux = Object.entries(dadosTabelaAux);
    preencherDadosTabelaMes(dadosTabelaAux);
    setDadosGraficoAgrupado2(data);
    setFiltrosAtivosLojasTexto(
      filtrosAtivosLojasTexto.split("- Loja")[0] + " - Loja " + loja
    );
    setNivelAtivoDoGrafico(2);
  }

  function preencherDadosVendasPorMes(codLoja) {
    setCodLojaAtiva(codLoja);
    setFiltrosAtivosLojasTexto(" Loja " + codLoja);

    preencherDadosGraficoAgrupadoGrupoMes(codLojaAtiva);
  }

  function iniciarFiltroPorPeriodo() {
    setTipoPeriodo("input");
    setAuxTipoPeriodo("input");
    setPeriodoAno(false);
    setPeriodoInputInicio(periodoInputInicioAux);
    setPeriodoInputFim(periodoInputFimAux);
  }

  function iniciarFiltroPorBase() {
    setBasesSelecionadas([...basesSelecionadasTemp]);
    setDadosDisponiveis(false);
    setAnosDisponiveis(false);
    setShowBaseFilterButton(false);
  }

  const fVoltar = () => {
    if (nivelAtivoDoGrafico == 1) {
      setCodLojaAtiva(false);
      setGrupoAtivo("");
      setFiltrosAtivosLojasTexto("");
    }
    if (nivelAtivoDoGrafico == 2) {
      let novoNivel = nivelAtivoDoGrafico - 1;
      setNivelAtivoDoGrafico(novoNivel);
      setDadosParaTabela(dadosParaTabelaLoja);
      let filtrosAtivos = filtrosAtivosLojasTexto.split("- Loja")[0];
      setFiltrosAtivosLojasTexto(filtrosAtivos);
      setLojaTable(true);
      setTipoPeriodo("input");
    } else if (nivelAtivoDoGrafico == 1) {
      setLojaTable(false);

      let novoNivel = nivelAtivoDoGrafico - 1;
      setNivelAtivoDoGrafico(novoNivel);
      setDadosParaTabela(auxDadosParaTabela);
    } else {
      return;
    }
  };

  function filtrarPorLoja() {
    let dataVendas = dadosGerais.filter((item) =>
      filtrosLojasSelecionadas.includes(Number(item.CodLoja))
    );
    let dataUltimoPeriodo = dadosGeraisPA.filter((item) =>
      filtrosLojasSelecionadas.includes(Number(item.CodLoja))
    );

    let ultimoPeriodoAgrupado = agruparPor(dataUltimoPeriodo, "CodGrp3");
    let ultimoPeriodo = Object.entries(ultimoPeriodoAgrupado);

    let grupoDeAnos = agruparPor(dataVendas, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    let separadoPorGrupo = [];
    grupoDeAnosArray.map((item) => {
      let grupoDeLoja = agruparPor(item[1], "CodGrp3");
      separadoPorGrupo.push(grupoDeLoja);
    });
    let resumoSeparadoPorGrupo = [];
    separadoPorGrupo.map((item) => {
      let item2 = Object.entries(item);
      item2.map((item3) => {
        let total = 0;
        let totalPedidos = 0;
        let ano;
        let codGrupo;
        let nomeFornecedor;
        let totalCusto = 0;
        let totalLucro = 0;
        let porcentagemLucro = 0;
        item3[1].map((item4, index) => {
          if (index == 0) {
            ano = item4.ano;
            nomeFornecedor = item4.NomeGrupo3;
            codGrupo = item4.CodGrp3;
          }
          totalPedidos += !isNaN(parseInt(item4.totalPedidos))
            ? parseInt(item4.totalPedidos)
            : 0;
          total += !isNaN(parseFloat(item4.VlrTotal))
            ? parseFloat(item4.VlrTotal)
            : 0;
          totalCusto += !isNaN(parseFloat(item4.VlrCusto))
            ? parseFloat(item4.VlrCusto)
            : 0;
          totalLucro += !isNaN(parseFloat(item4.VlrLucroBruto))
            ? parseFloat(item4.VlrLucroBruto)
            : 0;
          porcentagemLucro += !isNaN(parseFloat(item4.VlrLucroBrutoPorcentagem))
            ? parseFloat(item4.VlrLucroBrutoPorcentagem)
            : 0;
        });
        porcentagemLucro = porcentagemLucro / item3[1].length;
        let resumoLojaAno = {
          ano: ano,
          codGrupo,
          nomeFornecedor,
          total: total,
          totalPedidos: totalPedidos,
          totalCusto,
          totalLucro,
          porcentagemLucro,
        };
        resumoSeparadoPorGrupo.push(resumoLojaAno);
      });
    });

    var agrupado = agruparPor(resumoSeparadoPorGrupo, "ano");
    grupoDeAnosArray = Object.entries(agrupado);

    var periodoAnterior = [];

    ultimoPeriodo.map((item) => {
      item[1].map((item1) => {
        return periodoAnterior.push({
          CodGrp3: item1.CodGrp3,
          ano: item1.ano,
          nomeGrupo: item1.NomeGrupo3,
          porcentagemLucro: item1.VlrLucroBrutoPorcentagem,
          total: item1.VlrTotal,
          totalCusto: item1.VlrCusto,
          totalLucro: item1.VlrLucroBruto,
          totalPedidos: item1.totalPedidos,
        });
      });
    });

    preencherDadosGraficoAgrupado(
      grupoDeAnosArray,
      tipoPeriodo,
      auxAnoAtual,
      auxAnoAnterior,
      periodoAnterior
    );
    setDadosDisponiveis(true);
    setCarregando(false);
    setIsLoading(false);
  }

  function removeFiltroPorLoja() {
    var dataVendas = dadosGerais;
    var dataUltimoPeriodo = dadosGeraisPA;
    setDadosGerais(dataVendas);
    setDadosGeraisPA(dataUltimoPeriodo);
    let ultimoPeriodoAgrupado = agruparPor(dataUltimoPeriodo, "CodGrp3");
    let ultimoPeriodo = Object.entries(ultimoPeriodoAgrupado);
    let grupoDeAnos = agruparPor(dataVendas, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    let separadoPorGrupo = [];
    grupoDeAnosArray.map((item) => {
      let grupoDeLoja = agruparPor(item[1], "CodGrp3");
      separadoPorGrupo.push(grupoDeLoja);
    });
    let resumoSeparadoPorGrupo = [];
    separadoPorGrupo.map((item) => {
      let item2 = Object.entries(item);
      item2.map((item3) => {
        let total = 0;
        let totalPedidos = 0;
        let ano;
        let codGrupo;
        let nomeFornecedor;
        let totalCusto = 0;
        let totalLucro = 0;
        let porcentagemLucro = 0;
        item3[1].map((item4, index) => {
          if (index == 0) {
            ano = item4.ano;
            nomeFornecedor = item4.NomeGrupo3;
            codGrupo = item4.CodGrp3;
          }
          totalPedidos += !isNaN(parseInt(item4.totalPedidos))
            ? parseInt(item4.totalPedidos)
            : 0;
          total += !isNaN(parseFloat(item4.VlrTotal))
            ? parseFloat(item4.VlrTotal)
            : 0;
          totalCusto += !isNaN(parseFloat(item4.VlrCusto))
            ? parseFloat(item4.VlrCusto)
            : 0;
          totalLucro += !isNaN(parseFloat(item4.VlrLucroBruto))
            ? parseFloat(item4.VlrLucroBruto)
            : 0;
          porcentagemLucro += !isNaN(parseFloat(item4.VlrLucroBrutoPorcentagem))
            ? parseFloat(item4.VlrLucroBrutoPorcentagem)
            : 0;
        });
        porcentagemLucro = porcentagemLucro / item3[1].length;
        let resumoLojaAno = {
          ano: ano,
          codGrupo,
          nomeFornecedor,
          total: total,
          totalPedidos: totalPedidos,
          totalCusto,
          totalLucro,
          porcentagemLucro,
        };
        resumoSeparadoPorGrupo.push(resumoLojaAno);
      });
    });

    var agrupado = agruparPor(resumoSeparadoPorGrupo, "ano");
    grupoDeAnosArray = Object.entries(agrupado);

    var periodoAnterior = [];

    ultimoPeriodo.map((item) => {
      item[1].map((item1) => {
        return periodoAnterior.push({
          CodGrp3: item1.CodGrp3,
          ano: item1.ano,
          nomeGrupo: item1.NomeGrupo3,
          porcentagemLucro: item1.VlrLucroBrutoPorcentagem,
          total: item1.VlrTotal,
          totalCusto: item1.VlrCusto,
          totalLucro: item1.VlrLucroBruto,
          totalPedidos: item1.totalPedidos,
        });
      });
    });

    preencherDadosGraficoAgrupado(
      grupoDeAnosArray,
      tipoPeriodo,
      auxAnoAtual,
      auxAnoAnterior,
      periodoAnterior
    );
    setDadosDisponiveis(true);
    setCarregando(false);
    setIsLoading(false);
  }

  function filtrarPorLojaGrupo() {
    let dataVendas = dadosGeraisGrupo.filter((item) =>
      filtrosLojasSelecionadas.includes(Number(item.CodLoja))
    );
    let dataPeriodoAnterior = dadosGeraisGrupoPA.filter((item) =>
      filtrosLojasSelecionadas.includes(Number(item.CodLoja))
    );

    let grupoDeAnos = agruparPor(dataVendas, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    let separadoPorLoja = [];
    grupoDeAnosArray.map((item) => {
      let grupoDeLoja = agruparPor(item[1], "CodLoja");
      separadoPorLoja.push(grupoDeLoja);
    });

    const periodoAnteriorSeparadoPorLoja = agruparPor(
      dataPeriodoAnterior,
      "CodLoja"
    );
    let periodoAnterior = Object.entries(periodoAnteriorSeparadoPorLoja);
    let periodoAnteriorTemp = [];
    periodoAnterior.map((item) => {
      item[1].map((item2) => {
        periodoAnteriorTemp.push(Number(item2.VlrTotal));
      });
    });

    let resumoSeparadoPorLoja = [];
    separadoPorLoja.map((item) => {
      let item2 = Object.entries(item);
      item2.map((item3) => {
        let total = 0;
        let totalPedidos = 0;
        let ano;
        let codGrupo;
        let nomeGrupo;
        let totalCusto = 0;
        let totalLucro = 0;
        let porcentagemLucro = 0;
        let codLoja = 0;
        item3[1].map((item4, index) => {
          if (index == 0) {
            ano = item4.ano;
            nomeGrupo = item4.NomeGrupo3;
            codGrupo = item4.CodGrp3;
          }
          totalPedidos += !isNaN(parseInt(item4.totalPedidos))
            ? parseInt(item4.totalPedidos)
            : 0;
          total += !isNaN(parseFloat(item4.VlrTotal))
            ? parseFloat(item4.VlrTotal)
            : 0;
          totalCusto += !isNaN(parseFloat(item4.VlrCusto))
            ? parseFloat(item4.VlrCusto)
            : 0;
          totalLucro += !isNaN(parseFloat(item4.VlrLucroBruto))
            ? parseFloat(item4.VlrLucroBruto)
            : 0;
          porcentagemLucro += !isNaN(parseFloat(item4.VlrLucroBrutoPorcentagem))
            ? parseFloat(item4.VlrLucroBrutoPorcentagem)
            : 0;
          codLoja = item4.CodLoja;
        });
        porcentagemLucro = porcentagemLucro / item3[1].length;
        let resumoLojaAno = {
          ano: ano,
          codGrupo,
          nomeGrupo,
          total: total,
          totalPedidos: totalPedidos,
          totalCusto,
          totalLucro,
          porcentagemLucro,
          codLoja,
        };
        resumoSeparadoPorLoja.push(resumoLojaAno);
      });
    });
    var agrupado = agruparPor(resumoSeparadoPorLoja, "ano");
    grupoDeAnosArray = Object.entries(agrupado);
    preencherDadosGraficoAgrupadoGrupo(
      grupoDeAnosArray,
      tipoPeriodo,
      periodoAnteriorTemp,
      dataPeriodoAnterior
    );
    setIsLoading(false);
    setDadosDisponiveis(true);
    setCarregando(false);
  }

  function removeFiltroPorLojaGrupo() {
    let dataVendas = dadosGeraisGrupo;
    let dataPeriodoAnterior = dadosGeraisGrupoPA;

    let grupoDeAnos = agruparPor(dataVendas, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    let separadoPorLoja = [];
    grupoDeAnosArray.map((item) => {
      let grupoDeLoja = agruparPor(item[1], "CodLoja");
      separadoPorLoja.push(grupoDeLoja);
    });

    const periodoAnteriorSeparadoPorLoja = agruparPor(
      dataPeriodoAnterior,
      "CodLoja"
    );
    let periodoAnterior = Object.entries(periodoAnteriorSeparadoPorLoja);
    let periodoAnteriorTemp = [];
    periodoAnterior.map((item) => {
      item[1].map((item2) => {
        periodoAnteriorTemp.push(Number(item2.VlrTotal));
      });
    });

    let resumoSeparadoPorLoja = [];
    separadoPorLoja.map((item) => {
      let item2 = Object.entries(item);
      item2.map((item3) => {
        let total = 0;
        let totalPedidos = 0;
        let ano;
        let codGrupo;
        let nomeGrupo;
        let totalCusto = 0;
        let totalLucro = 0;
        let porcentagemLucro = 0;
        let codLoja = 0;
        item3[1].map((item4, index) => {
          if (index == 0) {
            ano = item4.ano;
            nomeGrupo = item4.NomeGrupo3;
            codGrupo = item4.CodGrp3;
          }
          totalPedidos += !isNaN(parseInt(item4.totalPedidos))
            ? parseInt(item4.totalPedidos)
            : 0;
          total += !isNaN(parseFloat(item4.VlrTotal))
            ? parseFloat(item4.VlrTotal)
            : 0;
          totalCusto += !isNaN(parseFloat(item4.VlrCusto))
            ? parseFloat(item4.VlrCusto)
            : 0;
          totalLucro += !isNaN(parseFloat(item4.VlrLucroBruto))
            ? parseFloat(item4.VlrLucroBruto)
            : 0;
          porcentagemLucro += !isNaN(parseFloat(item4.VlrLucroBrutoPorcentagem))
            ? parseFloat(item4.VlrLucroBrutoPorcentagem)
            : 0;
          codLoja = item4.CodLoja;
        });
        porcentagemLucro = porcentagemLucro / item3[1].length;
        let resumoLojaAno = {
          ano: ano,
          codGrupo,
          nomeGrupo,
          total: total,
          totalPedidos: totalPedidos,
          totalCusto,
          totalLucro,
          porcentagemLucro,
          codLoja,
        };
        resumoSeparadoPorLoja.push(resumoLojaAno);
      });
    });
    var agrupado = agruparPor(resumoSeparadoPorLoja, "ano");
    grupoDeAnosArray = Object.entries(agrupado);
    preencherDadosGraficoAgrupadoGrupo(
      grupoDeAnosArray,
      tipoPeriodo,
      periodoAnteriorTemp,
      dataPeriodoAnterior
    );
    setIsLoading(false);
    setDadosDisponiveis(true);
    setCarregando(false);
  }

  const iniciarFiltroPorLoja = (lojas) => {
    const lojasSelecionadas = lojas.map((item) => item.value);

    setFiltradoPorLoja(true);
    setFiltrosLojasSelecionadas(lojasSelecionadas);
  };

  const optionsChartBarVendas = {
    title: {
      display: true,
      padding: 20,
      text: "Vendas por Fornecedor",
    },
    legend: {
      position: "bottom",
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 25,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
      },
    },
  };

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      padding: 20,
      text: "Vendas por Fornecedor",
      align: "start",
    },
    legend: {
      position: "bottom",
      align: "start",
    },
    responsive: false,
    tooltips: {
      mode: "label",
    },
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 45,
            minRotation: 45,
            padding: 25,
          },
        },
      ],
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          gridLines: {
            display: true,
          },
          labels: {
            show: false,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
      },
    },
  };

  const getElementAtEvent = (element) => {
    if (!element.length) return;
    const { _index: index, _xScale: eixoX, _model: infoGrafico } = element[0];
    if (nivelAtivoDoGrafico == 0) {
      var nomeGrupo = eixoX.ticks[index];
      setLojaTable(true);
      setGrupoAtivo(nomeGrupo);
    } else if (nivelAtivoDoGrafico == 1) {
      setLojaTable(false);
      var nomeLoja = eixoX.ticks[index];
      preencherDadosGraficoAgrupadoGrupoMes(nomeLoja);
      setTipoPeriodo("mes");
    }
  };

  function calculateWidth(dados) {
    const x = dados.labels;
    let length = x ? x.length : 0;
    switch (true) {
      case length >= 0 && length <= 3:
        return 20000;
      case length >= 4 && length <= 50:
        return 20000;
      case length >= 51 && length <= 100:
        return 20000;
      default:
        return 20000;
    }
  }

  const filtrarBases = (codBase) => {
    setShowBaseFilterButton(true);

    setBasesSelecionadasTemp(codBase);
  };

  useEffect(() => {
    preencherInputPeriodo();
  }, []);

  useEffect(() => {
    if (nivelAcesso?.length === 0) {
      buscarNivelAcesso();
      setIsLoading(true);
      setCarregando(true);
      return;
    }
  }, [nivelAcesso, accessLevel, basesSelecionadas]);

  useEffect(() => {
    if (periodoAno && accessLevel?.length > 0 && acessBase?.length > 0) {
      preencherDados("ano");
    } else if (
      periodoInputInicio &&
      periodoInputFim &&
      accessLevel?.length > 0 &&
      acessBase?.length > 0
    ) {
      preencherDados("input");
    }
  }, [
    periodoInputInicio,
    periodoInputFim,
    periodoAno,
    basesSelecionadas,
    userData,
  ]);

  useEffect(() => {
    if (filtradoPorLoja && filtrosLojasSelecionadas?.length > 0) {
      if (grupoAtivo.trim() !== "") {
        filtrarPorLojaGrupo();
      } else {
        filtrarPorLoja();
      }
    } else if (filtradoPorLoja) {
      if (grupoAtivo.trim() !== "") {
        removeFiltroPorLojaGrupo();
      } else {
        removeFiltroPorLoja();
      }
    }
  }, [filtradoPorLoja, filtrosLojasSelecionadas]);

  useEffect(() => {
    if (grupoAtivo.trim() !== "") {
      preencherDadosFornecedorEspecifico(tipoPeriodo);
    }
  }, [grupoAtivo]);

  return (
    <>
      {PermissionsPage("vendaFornecedor") && (
        <>
          <Box ml={2}>
            <Title title="Vendas Por Fornecedor" />
          </Box>

          <Stack gap={2} direction={"row"} mx={2}>
            <Box component={Paper} variant="outlined" px={1} pb={1}>
              <FiltroPeriodo
                periodoInputInicioAux={periodoInputInicioAux}
                periodoInputFimAux={periodoInputFimAux}
                setPeriodoInputInicioAux={setPeriodoInputInicioAux}
                setPeriodoInputFimAux={setPeriodoInputFimAux}
              />
              <Stack gap={1}>
                <ButtonFilter
                  type="button"
                  onClick={() => iniciarFiltroPorPeriodo()}
                  disabled={isLoading}
                >
                  Filtrar
                </ButtonFilter>
                <ButtonToday
                  type="button"
                  onClick={() => iniciarFiltroHoje()}
                  disabled={isLoading}
                >
                  Hoje
                </ButtonToday>
              </Stack>
            </Box>

            <Box width={"100%"}>
              <Box pb={1}>
                <MultiSelectFilterBases
                  basesDisponiveis={basesDisponiveis}
                  basesSelecionadas={basesSelecionadasTemp}
                  onChange={filtrarBases}
                  disabled={isLoading}
                />
                <Typography variant="caption" component={"span"}>
                  *Atenção: Quando nenhuma base estiver selecionada, todas as
                  bases serão retornadas.*
                </Typography>
              </Box>

              <Box>
                <Typography
                  textAlign={"initial"}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  Lojas
                </Typography>
                <Select
                  options={lojasDisponiveis.map((item) => ({
                    label: converterLoja(item),
                    value: item,
                  }))}
                  isMulti
                  onChange={iniciarFiltroPorLoja}
                  placeholder="Selecione uma loja"
                  styles={colourStyles}
                  isDisabled={isLoading}
                />
                <Typography variant="caption" component={"span"}>
                  *Atenção: Quando nenhuma loja estiver selecionada, todas as
                  lojas serão retornadas.*
                </Typography>
              </Box>
              {showBaseFilterButton && (
                <Box>
                  <ButtonToday
                    type="button"
                    onClick={() => iniciarFiltroPorBase()}
                    disabled={isLoading}
                    style={{ width: "100%", marginTop: 16 }}
                  >
                    Filtrar bases
                  </ButtonToday>
                </Box>
              )}
            </Box>
          </Stack>

          {isLoading ? (
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"90vh"}
            >
              <LoadingTable isLoading={isLoading} />
            </Box>
          ) : (
            <>
              <Carregando carregando={carregando} />
              <>
                {dadosDisponiveis && (
                  <>
                    <Box
                      m={2}
                      p={1}
                      component={Paper}
                      variant="outlined"
                      style={{ overflow: "auto" }}
                    >
                      {nivelAtivoDoGrafico === 0 ? (
                        <Bar
                          height={400}
                          width={calculateWidth(dadosGraficoAgrupado)}
                          data={dadosGraficoAgrupado}
                          options={options}
                          getElementAtEvent={getElementAtEvent}
                        />
                      ) : (
                        ""
                      )}
                      {nivelAtivoDoGrafico === 1 ? (
                        <Bar
                          data={dadosGraficoAgrupado1}
                          options={optionsChartBarVendas}
                          getElementAtEvent={getElementAtEvent}
                        />
                      ) : (
                        ""
                      )}
                      {nivelAtivoDoGrafico === 2 ? (
                        <Bar
                          data={dadosGraficoAgrupado2}
                          options={optionsChartBarVendas}
                          getElementAtEvent={getElementAtEvent}
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                    <ButtonVoltar fVoltar={fVoltar} />
                  </>
                )}
              </>
              {dadosDisponiveis && exibeTabela ? (
                <>
                  <TituloTabela>VENDAS POR FORNECEDOR</TituloTabela>
                  <Box mx={2} variant="outlined" m={2}>
                    <TabelaNext
                      dadosParaTabela={dadosParaTabela}
                      colunas={vendasGrupoTabelaPorFornecedor(
                        tipoPeriodo,
                        anosDisponiveis,
                        lojaTable,
                        filtrosLojasSelecionadas
                      )}
                    />
                    {/* <LegendaTabela vendasPor={true}/> */}
                  </Box>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default VendasPorFornecedor;
