import { apiProd } from "./api";
import { api as apiV2 } from "./api.v2";

export const estoqueService = {
  buscaPlanejamentoOrcamentario: async (params) => {
    const response = apiProd
      .get("/financeiro/buscar-planejamento-orcametario.php", {
        params: params,
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  selectTabelasProduto: async (params) => {
    const response = apiProd
      .get("/bi/estoque/seleciona-tabelas-preco.php", {
        params: params,
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  buscaProduto: async (params) => {
    const response = apiProd
      .get("/bi/estoque/consulta-produto-por-codigo.php", {
        params: params,
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  salvaPromocao: async (body) => {
    const response = apiProd
      .post("/bi/estoque/salva-promocao.php", { ...body })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  getEstoqueMesPorLoja: async (base) => {
    const response = apiV2
      .get("/inventory/by-store-month", {
        params: {
          codBase: base,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return response;
  },
};
