import CellTable from "../../components/CellTable";
import { formatarNumero } from "../../util/loja";

export const colunasEstoqueContasPagar = [
  {
    name: <strong style={{ fontSize: "1rem" }}>Fornecedor</strong>,
    selector: (row) => row.nomeFor,
    sortable: true,
  },

  {
    name: <strong style={{ fontSize: "1rem" }}>Media Mês Qtd</strong>,
    selector: (row) => row.mediaMesQtd,
    sortable: true,
    cell: (row) => parseFloat(row.mediaMesQtd.toFixed(2)),
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Media Mês Custo</strong>,
    selector: (row) => row.mediaMesCusto,
    sortable: true,
    cell: (row) => parseFloat(row.mediaMesCusto.toFixed(2)),
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Custo Vendas(R$)</strong>,
    selector: (row) => row.custoVendas,
    sortable: true,
    cell: (row) => formatarNumero(row.custoVendas),
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Custo Estoque(R$)</strong>,
    selector: (row) => row.custo,
    sortable: true,
    cell: (row) => formatarNumero(row.custo),
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Total a Pagar</strong>,
    selector: (row) => row.totalPagar,
    sortable: true,
    cell: (row) => formatarNumero(row.totalPagar),
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Diferença</strong>,
    selector: (row) => row.diferenca,
    sortable: true,
    cell: (row) => <CellTable rowInfo={row.diferenca} />,
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>%</strong>,
    selector: (row) => row.porcentagem,
    sortable: true,
    cell: (row) => `${row.porcentagem.toFixed(2)} %`,
  },
];
