export function formatCurrency(value) {
  return new Intl.NumberFormat("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  }).format(parseFloat(value));
}

export function formatDecimal(
  value,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) {
  return new Intl.NumberFormat("pt-BR", {
    minimumFractionDigits,
    maximumFractionDigits,
    style: "decimal",
  }).format(parseFloat(value));
}
