import React, { useEffect } from "react";
import { useCallback, useState } from "react";
import { estoqueService } from "../../../services/estoqueServices";
import toast from "react-hot-toast";
import { useAccessFilter } from "../../../hooks/useAccessFilter";
import { formatDecimal } from "../../../util/number";
import { Tooltip } from "@mui/material";
import { converterLoja } from "../../../util/loja";

const columnsBegin = (length = 0) => [
  {
    name: "",
    selector: (row) => row.codGrupo,
    sortable: false,
    width: length >= 9 ? "30px" : "auto",
    cell: (row) => (
      <span className="fw-bold text-nowrap">
        {row.codGrupo !== "total" ? row.codGrupo : ""}
      </span>
    ),
  },
  {
    name: "",
    selector: (row) => row.nomeGrupo,
    sortable: false,
    compact: length >= 9,
    width: length >= 9 ? "110px" : "auto",
    cell: (row) => <span className="p-1 fw-bold">{row.nomeGrupo}</span>,
  },
];

const columnsEnd = (length = 0) => [
  {
    name: <span className="fw-normal w-100 text-center">E. Geral</span>,
    selector: (row) => row.qtdEstoque,
    sortable: false,
    width: length >= 9 ? "50px" : "auto",
    compact: length >= 9,
    cell: (row) => {
      return (
        <span className="text-nowrap p-1 text-center w-100">
          {formatDecimal(row.qtdEstoque, 0)}
        </span>
      );
    },
  },
  {
    name: <span className="fw-normal p-1 w-100 text-center">Custo Geral</span>,
    selector: (row) => row.qtdEstoque,
    sortable: false,
    compact: length >= 9,
    cell: (row) => {
      const textTooltip = `Grupo ${row.nomeGrupo} Custo Estoque ${formatDecimal(
        row.custoEstoque || 0
      )}`;

      const textTooltipMediaMes = `Grupo ${
        row.nomeGrupo || 0
      } Média Mês ${formatDecimal(row.mediaMesCusto)}`;

      return (
        <div
          className="p-1"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2px",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Tooltip title={textTooltip}>
            <span
              className="fw-bold text-nowrap"
              style={{ borderBottom: "1px dashed #ABA8A7" }}
            >
              {formatDecimal(row.custoEstoque)}
            </span>
          </Tooltip>
          <Tooltip title={textTooltipMediaMes}>
            <span>{formatDecimal(row.mediaMesCusto)}</span>
          </Tooltip>
        </div>
      );
    },
  },
];

export const useEstoqueMesLoja = () => {
  const {
    base,
    basesSelecionadas,
    basesDisponiveis,
    lojasSelecionadas,
    filtrarBases,
    setLojasSelecionadas,
  } = useAccessFilter();

  const [columns, setColumns] = useState([]);
  const [storeColumnsBackup, setStoreColumnsBackup] = useState([]);
  const [tableDataBackup, setTableDataBackup] = useState([]);

  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [estadosControle, setEstadosControle] = useState({
    erroReceberDadosRAD: false,
    graficoHandleClickActive: false,
  });
  const [tableData, setTableData] = useState([]);

  const getColumns = (storeColumns = []) => {
    const newColumns = [...columnsBegin(storeColumns.length)];
    storeColumns.forEach((item) => {
      newColumns.push({
        name: (
          <span className="p-1 fw-normal text-nowrap w-100 text-center">
            {item.label}
          </span>
        ),
        selector: (row) => row.lojas[item.selector],
        sortable: false,
        compact: storeColumns.length >= 0,
        width: storeColumns?.length >= 9 ? "75px" : "auto",
        cell: (row) => {
          const textTooltip = `${item.label} Grupo ${
            row.nomeGrupo
          } Custo Estoque ${formatDecimal(
            row.lojas[item.selector]?.custoEstoque
          )}`;

          const textTooltipMediaMes = `${item.label} Grupo ${
            row.nomeGrupo
          } Média Mês ${formatDecimal(
            row.lojas[item.selector]?.mediaMesCusto
          )}`;

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Tooltip title={textTooltip}>
                <span
                  className="fw-normal p-1"
                  style={{ borderBottom: "1px dashed #ABA8A7" }}
                >
                  {formatDecimal(row.lojas[item.selector]?.custoEstoque)}
                </span>
              </Tooltip>
              <Tooltip title={textTooltipMediaMes}>
                <span>
                  {formatDecimal(row.lojas[item.selector]?.mediaMesCusto)}
                </span>
              </Tooltip>
            </div>
          );
        },
      });
    });
    newColumns.push(...columnsEnd(storeColumns.length));
    setColumns(newColumns);
  };

  const getData = useCallback(async () => {
    const toastId = toast.loading("Esperando dados do RAD");
    setLojasSelecionadas([]);
    setIsLoading(true);
    const response = await estoqueService.getEstoqueMesPorLoja(base);
    setIsLoading(false);

    if (response.status !== 200) {
      toast.error("Erro ao receber dados do RAD", {
        id: toastId,
      });
      setEstadosControle({ ...estadosControle, erroReceberDadosRAD: true });
      setIsLoading(false);
      setLojasDisponiveis([]);
      return;
    }

    if (response.data) {
      const data = response.data;
      setLojasDisponiveis(
        !!data.storeColumns?.length
          ? data.storeColumns.map((item) =>
              converterLoja(parseInt(item.selector))
            )
          : []
      );
      setStoreColumnsBackup(data.storeColumns);
      getColumns(data.storeColumns);
      setTableData(data.data);
      setTableDataBackup(data.data);
      toast.success("Dados recebidos", {
        id: toastId,
      });
    }
  }, [base, estadosControle, lojasDisponiveis]);

  const handleFiltroLoja = (lojas = []) => {
    setLojasSelecionadas(
      lojas.map((item) => ({
        label: item,
        value: item,
      }))
    );
    if (!lojas.length) {
      setTableData(tableDataBackup);
      return getColumns(storeColumnsBackup);
    }
    const selectors = lojas.map((loja) => loja.split(" ")[0]);

    // Filtrar as lojas com base no seletor
    const filtered = storeColumnsBackup.filter((loja) =>
      selectors.includes(loja.selector)
    );

    const footer = {
      codGrupo: "total",
      nomeGrupo: "Total Geral",
      lojas: {},
      qtdEstoque: 0,
      custoEstoque: 0,
      custoVendas: 0,
      mediaMesCusto: 0,
    };

    const newTableData = tableDataBackup
      .filter((item) => item.codGrupo !== "total")
      .map((item) => {
        let custoEstoqueGrupo = 0;
        let custoVendasGrupo = 0;
        let qtdEstoqueGrupo = 0;
        let qtdVendasGrupo = 0;

        const lojas = {};
        filtered.forEach((itemFiltrado) => {
          lojas[itemFiltrado.selector] = item.lojas[itemFiltrado.selector];
        });

        Object.keys(lojas).forEach((itemKeyLoja) => {
          const element = lojas[itemKeyLoja];
          custoEstoqueGrupo += element.custoEstoque;
          custoVendasGrupo += element.custoVendas;
          qtdEstoqueGrupo += element.qtdEstoque;
          qtdVendasGrupo += element.qtdVendas;
        });

        return {
          ...item,
          lojas,
          custoEstoque: custoEstoqueGrupo,
          custoVendas: custoVendasGrupo,
          mediaMesCusto: !!custoVendasGrupo
            ? custoEstoqueGrupo / custoVendasGrupo
            : 0,
          qtdEstoque: qtdEstoqueGrupo,
          qtdVendas: qtdVendasGrupo,
        };
      });

    for (let j = 0; j < filtered.length; j++) {
      const elementStore = filtered[j];
      let custoEstoqueFooter = 0;
      let custoVendasFooter = 0;
      let qtdEstoque = 0;
      for (let i = 0; i < newTableData.length; i++) {
        const element = newTableData[i];
        custoEstoqueFooter += Number(
          element["lojas"][elementStore.selector].custoEstoque
        );
        custoVendasFooter += Number(
          element["lojas"][elementStore.selector].custoVendas
        );
        qtdEstoque += Number(
          element["lojas"][elementStore.selector].qtdEstoque
        );
      }
      footer.custoEstoque += custoEstoqueFooter;
      footer.qtdEstoque += qtdEstoque;
      footer.custoVendas += custoVendasFooter;

      footer["lojas"][elementStore.selector] = {
        custoEstoque: custoEstoqueFooter,
        custoVendas: custoVendasFooter,
        qtdEstoque: qtdEstoque,
        mediaMesCusto: custoVendasFooter
          ? custoEstoqueFooter / custoVendasFooter
          : 0,
      };
    }

    newTableData.sort((a, b) => b.custoEstoque - a.custoEstoque);

    newTableData.push({
      ...footer,
      mediaMesCusto: footer.custoEstoque / footer.custoVendas,
    });

    setTableData(newTableData);
    return getColumns(filtered);
  };

  const defaultValueBase = basesDisponiveis.find(
    (item) => item.codBase === Number(basesSelecionadas[0])
  );

  useEffect(() => {
    if (base) {
      getData();
    }
  }, [base]);

  return {
    isLoading,
    defaultValueBase,
    basesDisponiveis,
    basesSelecionadas,
    lojasDisponiveis,
    columns,
    tableData,
    lojasSelecionadas,
    filtrarBases,
    handleFiltroLoja,
  };
};
